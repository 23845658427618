import {
  convertJSONToArray,
  createElement,
  select,
  getPathForVapor,
  selectAll,
} from "@utils/function";

export default function MachplaySearch(update = false) {
  const matchplayPlayersContent = select(
    ".matchplay-players-content .slider-content"
  );

  const teamsArray = HQ.Properties.players;

  if (update) {
    const countryTeams = selectAll(".country-team");
    countryTeams.forEach((team) => {
      team.textContent = "";
    });
  } else {
    matchplayPlayersContent.textContent = "";

    const playerRowInformation = `
      <div class="player-row-information d-flex flex-sm-row">
        <div class="first-section">
            <div class="player-col text-nowrap h-100 mx-2">Select to find player:</div>
        </div>
        <div class="second-section d-flex">
            <div class="player-col ms-2 p-action">
                <span class="green-dot green-dot-active d-inline-block"></span>
                <span class="info-text">Active</span>
            </div>
            <div class="player-col ms-1 p-playing">
                <span class="player-icon">
                <svg fill="#3e3e3e">
                    <use href="#icon-flights"></use>
                </svg>
                </span>
                <span class="info-text">Playing</span>
            </div>
            <div class="player-col ms-2 p-practicing">
                <span class="driving-range">
                <svg fill="#3e3e3e">
                    <use href="#icon-driving-range"></use>
                </svg>
                </span>
                <span class="info-text">Practicing</span>
            </div>
        </div>
      </div>
    `;

    // head
    const teamSelection = `
    <div class="team-selection d-flex">
      <div class="team team-eur text-center col-6 p-1 is-active">Team EUR</div>
      <div class="team team-usa text-center col-6 p-1">Team USA</div>
    </div>
    ${playerRowInformation}
  `;

    matchplayPlayersContent.insertAdjacentHTML("afterbegin", teamSelection);
  }

  Object.entries(teamsArray).forEach(([key, value], index) => {
    if (!update) {
      const countryWrapper = createElement("div");
      if (index === 1) {
        countryWrapper.classList.add("d-none");
      }
      countryWrapper.classList.add(`country-wrapper-${key.toLowerCase()}`);
      countryWrapper.classList.add("country-team");
      countryWrapper.classList.add("container");
      matchplayPlayersContent.insertAdjacentElement(
        "beforeend",
        countryWrapper
      );
    }

    createRowForMatchPlayerSearch(value);
  });
}

function createRowForMatchPlayerSearch(value) {
  const countryWrapperEur = select(".country-wrapper-eur");
  const countryWrapperUsa = select(".country-wrapper-usa");

  Object.entries(value).forEach((player) => {
    let {
      id,
      isPlaying,
      isOnRangeArea,
      idglobal,
      isPositionTracked,
      country,
      image,
      isGpsTracked,
      firstname,
      lastname,
      place,
      description,
    } = player[1];

    const iconPLayerSVG = `
      <span class="player-icon">
        <svg fill="#3e3e3e">
          <use href="#icon-flights"></use>
        </svg>
      </span>
    `;

    const iconIsOnRange = `
      <span class="driving-range">
        <svg fill="#3e3e3e">
          <use href="#icon-driving-range"></use>
        </svg>
      </span>
    `;

    // drive-range
    // pitching-area
    // putting-green

    // console.log("description", description);

    const logoPoitive = checkIfPartnerExist(description);

    const playerInfo = `${firstname} ${lastname} ${
      logoPoitive != "" ? logoPoitive : description ? description : ""
    }`;

    const greenDot = isPositionTracked
      ? '<div class="green-dot green-dot-active"></div>'
      : '<div class="green-dot"></div>';

    const svgIsPlaying = isPlaying ? iconPLayerSVG : "";
    const svgIsOnRangeArea = isOnRangeArea ? iconIsOnRange : "";

    const cursorPointer =
      isPlaying || isOnRangeArea || (isGpsTracked && place?.id)
        ? "cursor-pointer"
        : "";

    const gotoToArea = isOnRangeArea
      ? `data-go-to-area="${isOnRangeArea}"`
      : "";

    const dataId =
      isGpsTracked && place?.id ? `data-id=${place.id}` : `data-id=${idglobal}`;

    const dataFullName = `data-full-name="${firstname} ${lastname}"`;

    const dataFlightToPlace =
      isPlaying || isOnRangeArea || isGpsTracked
        ? `data-flight-to-place="true"`
        : "";

    const captain = isGpsTracked ? `data-captain="true"` : "";

    image = image
      ? `<img loading="lazy" src="${image}" height="50">`
      : '<svg fill="#efefef"><use href="#icon-placeholder-man"></use></svg >';

    const placeID =
      iconIsOnRange && place?.id ? `data-place-area="${place.id}"` : "";

    const templatePlayer = `
        <div class="player-row row p-2 ${cursorPointer}" ${dataFullName} ${dataId} ${placeID} ${dataFlightToPlace} ${gotoToArea} ${captain}>
          <div class="col-1 d-flex align-items-center p-0">
            <span title="${country}" class="flag-icon flag-icon-match flag-icon-${country.toLowerCase()}"></span>
          </div>
          <div class="col-2 mach-player-image">
            ${image}
          </div>
          <div class="col-6 d-flex align-items-center">${playerInfo}</div>
          <div class="col-2 d-flex justify-content-center align-items-center mach-player-icon p-0">${svgIsPlaying}${svgIsOnRangeArea}</div>
          <div class="col-1 d-flex p-0 justify-content-center align-items-center">${greenDot}</div>
        </div>
      `;

    if (country === "EUR") {
      countryWrapperEur?.insertAdjacentHTML("beforeend", templatePlayer);
    } else {
      countryWrapperUsa?.insertAdjacentHTML("beforeend", templatePlayer);
    }
  });
}

function checkIfPartnerExist(description) {
  const partner = convertJSONToArray(HQ.Properties.partners)
    .map(({ name, logo_positive }) => {
      if (name === description) {
        return `<img src="${getPathForVapor(
          logo_positive
        )}" class="ms-2 img-fluid player-logo" loading="lazy">`;
      }
    })
    .join("");
  return partner;
}
